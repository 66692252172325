import React from "react";
import cx from "classnames";

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  type?: "submit" | "reset" | "button";
}

const Button: React.FC<ButtonProps> = ({
  className,
  type,
  children,
  ...props
}) => {
  return (
    <button
      type={type}
      {...props}
      className={cx(
        "px-20 py-5 font-bold text-white rounded-full bg-primary",
        className
      )}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  type: "button",
};

export default React.memo(Button);
